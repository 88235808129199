import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "メドレーで初めての技術職インターンシップを実施しました",
  "date": "2016-10-18T08:06:03.000Z",
  "slug": "entry/2016/10/18/170603",
  "tags": ["medley"],
  "hero": "./2016_10_18.png",
  "heroAlt": "intern"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」の開発を担当している新居です。 10 月になり肌寒い季節になってきましたが、メドレーでは今年の夏の 8 月から 9 月の間で技術職`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3%A5%B7%A5%C3%A5%D7"
      }}>{`インターンシップ`}</a>{`（以下、技術`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`）を実施しました。`}</p>
    <p>{`最初に少しメドレーのエンジニアについて紹介すると、メドレーにはエンジニアが所属する開発本部があり、昨年 2015 年 7 月に CTO の平山が参画してから整備されてきました。弊社自体は 2009 年創業ではあるものの、正式に開発本部が立ち上がってからは 1 年弱です。開発本部には 10 月現在 14 名が所属しており、`}<a parentName="p" {...{
        "href": "https://info.medley.jp/entry/2016/08/26/144702"
      }}>{`こちらの記事`}</a>{`でも紹介しましたが医療 xIT という領域でプロダクトの開発をゴリゴリ進めています。`}</p>
    <p>{`このようにまだまだスタートアップフェーズを走っている段階であり、教育や技術`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`開催に使える時間も限られている中ではありましたが、その中で最大限の成果を出せるよう、実施に向けて取り組むことになりました。自分自身としても`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`生のメンターをするのは初でしたが、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`生とメンターである自分、そしてプロダクトを通じて社会のため（それが会社のためでもある）にしっかり価値を残せるようチャレンジしました。`}</p>
    <p>{`ということで少し前置きが長くなりましたが、今回はメドレーで行った技術`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`の取り組みをひとつの事例として紹介してみようと思います。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20161017/20161017184047.jpg",
        "alt": "f:id:medley_inc:20161017184047j:plain",
        "title": "f:id:medley_inc:20161017184047j:plain"
      }}></img></p>
    <h1>{`【企画 1】まずはインタビュー`}</h1>
    <p>{`今回の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`生は某大学の 3 年生 1 名で、まずはどういう流れで進めていくかを決めるために簡単にインタビューを行いました。 インタビューの内容は`}</p>
    <ul>
      <li parentName="ul">{`「なぜ技術`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
        }}>{`インターン`}</a>{`をやりたいのか？」`}</li>
      <li parentName="ul">{`「大学ではどういう勉強をしてるのか？」`}</li>
      <li parentName="ul">{`「技術スキルはどれくらいか？（どの言語を書いたことがあるかとか）」`}</li>
    </ul>
    <p>{`といった内容で、今回は受け入れ前提だったので技術試験などは行っていません。 インタビューの結果、細かい内容は伏せますが今回の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`生は大学の授業でプログラミングや簡単な`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/UNIX"
      }}>{`UNIX`}</a>{`コマンドに触れたことがあるというレベル感で、「将来エンジニアの道に進むかどうか悩んでいる」「開発の流れをひと通り経験してみたい」という要望を持っていました。`}</p>
    <h1>{`【企画 2】ゴールと内容の大枠を決める`}</h1>
    <p>{`インタビューの結果を踏まえて、技術`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`で行う内容を決めていきます。インタビューによって`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`生が求めていることがわかったので、それを満たせるようにゴール設定をします。今回だと「開発の一通りの流れを経験し、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`生本人の今後の進路決定のための判断材料やヒントを得てもらうこと」というゴールを設定しました。`}</p>
    <p>{`また、冒頭でも述べましたが、スタートアップフェーズで少人数でプロダクト開発を進めている最中なので、メンターである自分の開発業務も疎かにできません。実際に開発・運用しているプロダクトでしっかりアウトプットを出し続けることが大切なので、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`生のレベル云々に関係なく、技術`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`でもどうにか実際に稼働しているプロダクトでアウトプットを出せるような企画を考えました。`}</p>
    <p>{`そこで以下のような企画の大枠イメージを定義しました。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
        }}>{`インターン`}</a>{`生、自分、社会（会社）に価値を残すために、実プロダクト上でアウトプットをしっかり出す`}</li>
      <li parentName="ul">{`実プロダクト上でアウトプットを出すことで得られる達成感や喜びを感じてもらう`}</li>
      <li parentName="ul">{`ひと通りの開発フローを経験してもらう`}</li>
      <li parentName="ul">{`メンターである自分にも開発業務があるので`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/OJT"
        }}>{`OJT`}</a>{`形式で行う`}</li>
    </ul>
    <h1>{`【企画 3】詳細な内容を決める`}</h1>
    <p>{`ゴールと大枠を決めた後は、企画の詳細な内容を決めていきます。 以下のようなフェーズ 1〜4 を定義してみました。`}</p>
    <h2>{`フェーズ 1「基本的なウェブアプリケーション開発のフローを経験する」`}</h2>
    <p>{`今回の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`生のレベル感を踏まえて、まずは`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`と`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Rails"
      }}>{`Rails`}</a>{`を使った基本的なウェブアプリケーション開発のフローを経験してもらうことにしました（`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`と`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Rails"
      }}>{`Rails`}</a>{`は今回関わる実プロダクトで使っているため）。そこで教材として`}<a parentName="p" {...{
        "href": "https://railstutorial.jp/"
      }}>{`Ruby on Rails チュートリアル：実例を使って Rails を学ぼう`}</a>{`を使用しました。ご存知の方も多いと思いますが、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`と`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Rails"
      }}>{`Rails`}</a>{`を使いながらウェブアプリケーション開発を体系的に学べるとても良い教材です。`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Ruby"
      }}>{`Ruby`}</a>{`と`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Rails"
      }}>{`Rails`}</a>{`はもちろんのこと、Git の使い方、デプロイ、テストといった実プロダクトの開発で必要不可欠な要素も含まれており、手厚いフォローがなくとも手を動かしながら進められるということで今回採用することになりました。`}</p>
    <h2>{`フェーズ 2「実プロダクトでウェブアプリケーション開発のフローを経験する（小〜中規模）」`}</h2>
    <p>{`フェーズ 1 で流れを掴んだら、ここから実プロダクトの開発に関わってもらうことにしました。まずは文言の修正や追加などを通じて実プロダクト上での開発フローを経験してもらいます。`}</p>
    <h2>{`フェーズ 3「実プロダクトでウェブアプリケーション開発のフローを経験する（中〜大規模）」`}</h2>
    <p>{`フェーズ 2 で慣れてきたらできる範囲で徐々に規模を大きくしていきます。 （小〜中、中〜大など抽象的なところはありますが、細かい定義は省略します）`}</p>
    <h2>{`フェーズ 4「難しい課題解決にチャレンジしてアウトプットを出す」`}</h2>
    <p>{`フェーズ 3 の上位として実プロダクト上での難しい課題解決にチャレンジしてもらいます。ここは必須ではないですが、一応設定しておきました。`}</p>
    <p>{`このような感じでフェーズに区切って定義してみました。`}</p>
    <h1>{`【企画 4】ゴールと内容を共有し認識を合わせる`}</h1>
    <p>{`ゴールと内容が決まったら、それらを`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`生に共有し、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`生のやりたいこととズレがないようしっかり認識を合わせました。問題がなければ開始日からどういう流れで進めていくかのスケジュールもすり合わせし、開始日を待つことになります。`}</p>
    <h1>{`【実施】実際に実施した流れを紹介`}</h1>
    <p>{`いよいよ当日です。当初の計画通りにフェーズ 1 から実施しました。 実際にどういう流れで進んでいったか紹介してみます。`}</p>
    <h2>{`初日から 4 日目`}</h2>
    <ul>
      <li parentName="ul">{`フェーズ 1 の「`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/Ruby%20on%20Rails"
        }}>{`Ruby on Rails`}</a>{` `}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%C1%A5%E5%A1%BC%A5%C8%A5%EA%A5%A2%A5%EB"
        }}>{`チュートリアル`}</a>{`」を進めた`}</li>
      <li parentName="ul">{`「`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/Ruby%20on%20Rails"
        }}>{`Ruby on Rails`}</a>{` `}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%C1%A5%E5%A1%BC%A5%C8%A5%EA%A5%A2%A5%EB"
        }}>{`チュートリアル`}</a>{`」の第 9 章のはじめくらいまで進めることができた`}</li>
      <li parentName="ul">{`時間の都合もありここで「`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/Ruby%20on%20Rails"
        }}>{`Ruby on Rails`}</a>{` `}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%C1%A5%E5%A1%BC%A5%C8%A5%EA%A5%A2%A5%EB"
        }}>{`チュートリアル`}</a>{`」は終了した`}</li>
    </ul>
    <h2>{`5 日目`}</h2>
    <ul>
      <li parentName="ul">{`ここから実プロダクトの開発環境構築を行った`}</li>
      <li parentName="ul">{`早速実プロダクトの文言調整などを行った（ここでブランチ操作や PR の出し方なども経験）`}</li>
    </ul>
    <h2>{`6 日目から 9 日目`}</h2>
    <ul>
      <li parentName="ul">{`実プロダクトで小さい開発業務を数件こなした`}</li>
      <li parentName="ul">{`表示まわりの調整が中心だが、データベースから必要なデータを取得したり、条件による出し分けをしたり、プロダクト開発で考えないといけないことを経験した`}</li>
      <li parentName="ul">{`ここまでで実プロダクト上でのひと通りの開発フローを経験した`}</li>
    </ul>
    <h2>{`10 日目から 13 日目`}</h2>
    <ul>
      <li parentName="ul">{`実プロダクトの中規模な開発業務を経験した（内容はメディア系のプロダクトの新規ページの追加）`}</li>
      <li parentName="ul">{`ルーティング、コントローラ、ビューなどのページ遷移に必要な処理を実装した`}</li>
      <li parentName="ul">{`新規ページに表示する情報の取得、情報の整形や表示まわりの処理を実装した`}</li>
      <li parentName="ul">{`データーベースへのデータのインサート、アップデートなども経験した`}</li>
    </ul>
    <h2>{`14 日目から 17 日目`}</h2>
    <ul>
      <li parentName="ul">{`テストコードを追加した`}</li>
      <li parentName="ul">{`普段手動で行っていたことの自動化をする`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%B9%A5%AF%A5%EA%A5%D7%A5%C8"
        }}>{`スクリプト`}</a>{`の開発をした（後回しにしていた課題などの解決）`}</li>
      <li parentName="ul">{`日程の都合もありここで技術`}<a parentName="li" {...{
          "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
        }}>{`インターン`}</a>{`は一区切り`}</li>
    </ul>
    <p>{`こんな感じで一気に紹介してみましたが、随所で適宜フォローを入れつつ短期間で実プロダクト上でアウトプットをしっかり出すことができました。もちろん`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`生の成果物はしっかりコードレビューと品質レビューをした後、本番にリリースされました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20161017/20161017184012.png",
        "alt": "f:id:medley_inc:20161017184012p:plain",
        "title": "f:id:medley_inc:20161017184012p:plain"
      }}></img></p>
    <h1>{`【実施】感想`}</h1>
    <p>{`今回の技術`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`終了後、企画していたフェーズ 3 くらいまでは到達でき、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`生からも当初の目的を満たせたという感想を頂くことができました。ひと通りの開発フローを経験できたこと、実プロダクト上で作ったものが本番環境にリリースされるドキドキや達成感を感じてもらえて、良い経験になったのではないかと思います。`}</p>
    <p>{`スタートアップということで普段の開発業務と並行し、数日ガッツリ時間をとってフォローするなどはできませんでしたが、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`生の目的を満たせたこと、そして実プロダクト上で新しい機能を追加してリリースできたり、普段の自分の業務で手が回らず後回しになっていた課題なども解決することができ、`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`生と自分、社会（会社）のために価値を残すことができたのではないかと思います。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`ということで、今回はメドレーで実施した技術`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`の事例を紹介してみました。スタートアップというフェーズらしく、意思決定も柔軟で、今回のような技術`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%A4%A5%F3%A5%BF%A1%BC%A5%F3"
      }}>{`インターン`}</a>{`の企画・実施という突発的な業務であったり、会社の成長と共に生まれる様々な問題や課題の解決に奔走することも多々ありますが、それもまた醍醐味でしょう。働く環境の整備やエンジニアの文化形成も徐々に進められており、大変なこともありますがスタートアップフェーズでしか経験できないような濃い仕事がたくさんあります。集まっているメンバーは豊富な経験を持った 30 代が中心ですが、もちろん 20 代でも、医療 xIT で挑戦したいやる気ある方を募集中ですので、興味のある方はぜひご連絡ください！`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="株式会社メドレーの採用/求人一覧 - Wantedly" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.wantedly.com%2Fcompanies%2Fmedley%2Fprojects" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.wantedly.com/companies/medley/projects">www.wantedly.com</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      